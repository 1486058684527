import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Footer from "./Footer";
import Navbar from "./Navbar";
import GTranslate from './Components/GTranslate'

import "./Archive.css";

import db from "./firebase";
import Search from "./Search";

const Archive = () => {
  const [searches, setSearches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    db.collection("location")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        const d = snapshot.docs.map((doc) => ({
          data: doc.data(),
          id: doc.id,
        }));
        console.log(`Response Length: ${d.length}`);
        setSearches(d);
      });
  }, []);
  
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'google_translate_element')
  //  }
  //  useEffect(() => {
  //   var addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  //   console.log('/en/'+ params +'');

  //   setcookie('googtrans', '/en/'+ params.id +'', 1);
  // }, [])
  
  // const setcookie = (key, value, expiry) => {
  //               var expires = new Date();
  //               expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
  //               document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
  //  }

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  const dataPerPage = 10;
  const indexOfLast = currentPage * dataPerPage;
  const indexOfFirst = indexOfLast - dataPerPage;
  const currentData = searches.slice(indexOfFirst, indexOfLast);
  const TotalPage = Math.ceil(searches.length / dataPerPage);


  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(searches.length / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  const setNextPage = () => {   
     setCurrentPage(currentPage + 1);
  }
  const setPrivious = () => {
    setCurrentPage(currentPage - 1);
  }

  return (
    <div className="archive">
      <Navbar />
      <main>       
        {currentData.map((search) => (
          <Search
            location={search.data.location}
            date={search.data.date}
            key={search.data.id}
            />
        ))}
        <div className="pagination">
        <center>
        <a  style={currentPage == 1 ? {visibility:"hidden"} : {visibility:"visible"}} onClick={() => setPrivious()}>Pervious Page</a>
           
        <a  style={currentPage == TotalPage ? {visibility:"hidden"} : {visibility:"visible"}} onClick={() => setNextPage()}>Next Page</a>
        </center>    
        </div>
      </main>
      <Footer />
      <GTranslate />
    </div>
  );
};

export default Archive;
