import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import "./App.css";
import About from "./About";
import HomePage from "./HomePage";
import Archive from "./Archive";
import Venue from "./Venue";
import LangCode from "./LangCode";

function App() {
  //
  return (
    <div className="app">
      {/* Homepage */}
      {/* <HomePage /> */}
      {/* About */}
      {/* <About /> */}
      {/* Archive */}
      {/* Admin */}
      {/* <Admin /> */}

      <Router>
        <Switch>
          {/* <Route exact path="/">
            <HomePage />
          </Route> */}
          <Redirect from="/" to="/home" exact />
          <Route exact path="/home/:id?">
            <HomePage />
          </Route>
          <Route path="/about/:id?">
            <About />
          </Route>
          <Route path="/archive/:id?">
            <Archive />
          </Route>
          <Route path="/LangCode/:id?">
            <LangCode />
          </Route>
          <Route path="/venue">
            <Venue />
          </Route>
          {/* <Route path="/archive">
              <Archive
            </Route> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
