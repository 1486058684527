import React, { useEffect } from "react";
import { useParams } from "react-router";

const GTranslate = () => {
  const params = useParams();
  const { id } = params;

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
     
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    if (id !== undefined) {
       eraseCookie("googtrans", ".adresor.com"); //erases the .one.om domain cookie
       eraseCookie("googtrans", "")
       createCookie("googtrans", "/en/" + id + "", 1,".adresor.com");
    }
  }, []);

  // const setcookie = (key, value, expiry) => {
  //   document.cookie =
  //     key +
  //     "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.adresor.com";
  //   document.cookie =
  //     key +
  //     "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=adresor.com";

  //   const CookieDate = new Date();
  //   CookieDate.setFullYear(CookieDate.getFullYear() + 10);
  //   const strDate = CookieDate.toUTCString();
  //   const myCookie =
  //     key +
  //     "=" +
  //     value +
  //     ";expires=" +
  //     strDate +
  //     "; path=/";

  //   document.cookie = myCookie;

  // };
  function createCookie(name, value, days, domain) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else {
      var expires = "";
    }
    document.cookie =
      name + "=" + value + expires + "; domain=" + domain + "; path=/";
  }
  function eraseCookie(name, domain) {
    createCookie(name, "", -1, domain);
  }

  return <div></div>;
};

export default GTranslate;
