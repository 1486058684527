import React from "react";
import "./Langcode.css";
import Footer from "./Footer";
import Navbar from "./Navbar";
import GTranslate from "./Components/GTranslate";

const LangCode = () => {
  return (
    <div className="LangCode">
      <Navbar />
      <main>
        <table>
          <thead>
            <tr>
              <th>Language</th>
              <th>ISO-639-1 Code</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Afrikaans</td>
              <td>
                <code translate="no" dir="ltr">
                  af
                </code>
              </td>
            </tr>
            <tr>
              <td>Albanian</td>
              <td>
                <code translate="no" dir="ltr">
                  sq
                </code>
              </td>
            </tr>
            <tr>
              <td>Amharic</td>
              <td>
                <code translate="no" dir="ltr">
                  am
                </code>
              </td>
            </tr>
            <tr>
              <td>Arabic</td>
              <td>
                <code translate="no" dir="ltr">
                  ar
                </code>
              </td>
            </tr>
            <tr>
              <td>Armenian</td>
              <td>
                <code translate="no" dir="ltr">
                  hy
                </code>
              </td>
            </tr>
            <tr>
              <td>Azerbaijani</td>
              <td>
                <code translate="no" dir="ltr">
                  az
                </code>
              </td>
            </tr>
            <tr>
              <td>Basque</td>
              <td>
                <code translate="no" dir="ltr">
                  eu
                </code>
              </td>
            </tr>
            <tr>
              <td>Belarusian</td>
              <td>
                <code translate="no" dir="ltr">
                  be
                </code>
              </td>
            </tr>
            <tr>
              <td>Bengali</td>
              <td>
                <code translate="no" dir="ltr">
                  bn
                </code>
              </td>
            </tr>
            <tr>
              <td>Bosnian</td>
              <td>
                <code translate="no" dir="ltr">
                  bs
                </code>
              </td>
            </tr>
            <tr>
              <td>Bulgarian</td>
              <td>
                <code translate="no" dir="ltr">
                  bg
                </code>
              </td>
            </tr>
            <tr>
              <td>Catalan</td>
              <td>
                <code translate="no" dir="ltr">
                  ca
                </code>
              </td>
            </tr>
            <tr>
              <td>Cebuano</td>
              <td>
                <code translate="no" dir="ltr">
                  ceb
                </code>{" "}
                (<a href="https://en.wikipedia.org/wiki/ISO_639-2">ISO-639-2</a>
                )
              </td>
            </tr>
            <tr>
              <td>Chinese (Simplified)</td>
              <td>
                <code translate="no" dir="ltr">
                  zh-CN
                </code>{" "}
                or{" "}
                <code translate="no" dir="ltr">
                  zh
                </code>{" "}
                (<a href="https://tools.ietf.org/html/bcp47">BCP-47</a>)
              </td>
            </tr>
            <tr>
              <td>Chinese (Traditional)</td>
              <td>
                <code translate="no" dir="ltr">
                  zh-TW
                </code>{" "}
                (<a href="https://tools.ietf.org/html/bcp47">BCP-47</a>)
              </td>
            </tr>
            <tr>
              <td>Corsican</td>
              <td>
                <code translate="no" dir="ltr">
                  co
                </code>
              </td>
            </tr>
            <tr>
              <td>Croatian</td>
              <td>
                <code translate="no" dir="ltr">
                  hr
                </code>
              </td>
            </tr>
            <tr>
              <td>Czech</td>
              <td>
                <code translate="no" dir="ltr">
                  cs
                </code>
              </td>
            </tr>
            <tr>
              <td>Danish</td>
              <td>
                <code translate="no" dir="ltr">
                  da
                </code>
              </td>
            </tr>
            <tr>
              <td>Dutch</td>
              <td>
                <code translate="no" dir="ltr">
                  nl
                </code>
              </td>
            </tr>
            <tr>
              <td>English</td>
              <td>
                <code translate="no" dir="ltr">
                  en
                </code>
              </td>
            </tr>
            <tr>
              <td>Esperanto</td>
              <td>
                <code translate="no" dir="ltr">
                  eo
                </code>
              </td>
            </tr>
            <tr>
              <td>Estonian</td>
              <td>
                <code translate="no" dir="ltr">
                  et
                </code>
              </td>
            </tr>
            <tr>
              <td>Finnish</td>
              <td>
                <code translate="no" dir="ltr">
                  fi
                </code>
              </td>
            </tr>
            <tr>
              <td>French</td>
              <td>
                <code translate="no" dir="ltr">
                  fr
                </code>
              </td>
            </tr>
            <tr>
              <td>Frisian</td>
              <td>
                <code translate="no" dir="ltr">
                  fy
                </code>
              </td>
            </tr>
            <tr>
              <td>Galician</td>
              <td>
                <code translate="no" dir="ltr">
                  gl
                </code>
              </td>
            </tr>
            <tr>
              <td>Georgian</td>
              <td>
                <code translate="no" dir="ltr">
                  ka
                </code>
              </td>
            </tr>
            <tr>
              <td>German</td>
              <td>
                <code translate="no" dir="ltr">
                  de
                </code>
              </td>
            </tr>
            <tr>
              <td>Greek</td>
              <td>
                <code translate="no" dir="ltr">
                  el
                </code>
              </td>
            </tr>
            <tr>
              <td>Gujarati</td>
              <td>
                <code translate="no" dir="ltr">
                  gu
                </code>
              </td>
            </tr>
            <tr>
              <td>Haitian Creole</td>
              <td>
                <code translate="no" dir="ltr">
                  ht
                </code>
              </td>
            </tr>
            <tr>
              <td>Hausa</td>
              <td>
                <code translate="no" dir="ltr">
                  ha
                </code>
              </td>
            </tr>
            <tr>
              <td>Hawaiian</td>
              <td>
                <code translate="no" dir="ltr">
                  haw
                </code>{" "}
                (<a href="https://en.wikipedia.org/wiki/ISO_639-2">ISO-639-2</a>
                )
              </td>
            </tr>
            <tr>
              <td>Hebrew</td>
              <td>
                <code translate="no" dir="ltr">
                  he
                </code>{" "}
                or{" "}
                <code translate="no" dir="ltr">
                  iw
                </code>
              </td>
            </tr>
            <tr>
              <td>Hindi</td>
              <td>
                <code translate="no" dir="ltr">
                  hi
                </code>
              </td>
            </tr>
            <tr>
              <td>Hmong</td>
              <td>
                <code translate="no" dir="ltr">
                  hmn
                </code>{" "}
                (<a href="https://en.wikipedia.org/wiki/ISO_639-2">ISO-639-2</a>
                )
              </td>
            </tr>
            <tr>
              <td>Hungarian</td>
              <td>
                <code translate="no" dir="ltr">
                  hu
                </code>
              </td>
            </tr>
            <tr>
              <td>Icelandic</td>
              <td>
                <code translate="no" dir="ltr">
                  is
                </code>
              </td>
            </tr>
            <tr>
              <td>Igbo</td>
              <td>
                <code translate="no" dir="ltr">
                  ig
                </code>
              </td>
            </tr>
            <tr>
              <td>Indonesian</td>
              <td>
                <code translate="no" dir="ltr">
                  id
                </code>
              </td>
            </tr>
            <tr>
              <td>Irish</td>
              <td>
                <code translate="no" dir="ltr">
                  ga
                </code>
              </td>
            </tr>
            <tr>
              <td>Italian</td>
              <td>
                <code translate="no" dir="ltr">
                  it
                </code>
              </td>
            </tr>
            <tr>
              <td>Japanese</td>
              <td>
                <code translate="no" dir="ltr">
                  ja
                </code>
              </td>
            </tr>
            <tr>
              <td>Javanese</td>
              <td>
                <code translate="no" dir="ltr">
                  jv
                </code>
              </td>
            </tr>
            <tr>
              <td>Kannada</td>
              <td>
                <code translate="no" dir="ltr">
                  kn
                </code>
              </td>
            </tr>
            <tr>
              <td>Kazakh</td>
              <td>
                <code translate="no" dir="ltr">
                  kk
                </code>
              </td>
            </tr>
            <tr>
              <td>Khmer</td>
              <td>
                <code translate="no" dir="ltr">
                  km
                </code>
              </td>
            </tr>
            <tr>
              <td>Kinyarwanda</td>
              <td>
                <code translate="no" dir="ltr">
                  rw
                </code>
              </td>
            </tr>
            <tr>
              <td>Korean</td>
              <td>
                <code translate="no" dir="ltr">
                  ko
                </code>
              </td>
            </tr>
            <tr>
              <td>Kurdish</td>
              <td>
                <code translate="no" dir="ltr">
                  ku
                </code>
              </td>
            </tr>
            <tr>
              <td>Kyrgyz</td>
              <td>
                <code translate="no" dir="ltr">
                  ky
                </code>
              </td>
            </tr>
            <tr>
              <td>Lao</td>
              <td>
                <code translate="no" dir="ltr">
                  lo
                </code>
              </td>
            </tr>
            <tr>
              <td>Latvian</td>
              <td>
                <code translate="no" dir="ltr">
                  lv
                </code>
              </td>
            </tr>
            <tr>
              <td>Lithuanian</td>
              <td>
                <code translate="no" dir="ltr">
                  lt
                </code>
              </td>
            </tr>
            <tr>
              <td>Luxembourgish</td>
              <td>
                <code translate="no" dir="ltr">
                  lb
                </code>
              </td>
            </tr>
            <tr>
              <td>Macedonian</td>
              <td>
                <code translate="no" dir="ltr">
                  mk
                </code>
              </td>
            </tr>
            <tr>
              <td>Malagasy</td>
              <td>
                <code translate="no" dir="ltr">
                  mg
                </code>
              </td>
            </tr>
            <tr>
              <td>Malay</td>
              <td>
                <code translate="no" dir="ltr">
                  ms
                </code>
              </td>
            </tr>
            <tr>
              <td>Malayalam</td>
              <td>
                <code translate="no" dir="ltr">
                  ml
                </code>
              </td>
            </tr>
            <tr>
              <td>Maltese</td>
              <td>
                <code translate="no" dir="ltr">
                  mt
                </code>
              </td>
            </tr>
            <tr>
              <td>Maori</td>
              <td>
                <code translate="no" dir="ltr">
                  mi
                </code>
              </td>
            </tr>
            <tr>
              <td>Marathi</td>
              <td>
                <code translate="no" dir="ltr">
                  mr
                </code>
              </td>
            </tr>
            <tr>
              <td>Mongolian</td>
              <td>
                <code translate="no" dir="ltr">
                  mn
                </code>
              </td>
            </tr>
            <tr>
              <td>Myanmar (Burmese)</td>
              <td>
                <code translate="no" dir="ltr">
                  my
                </code>
              </td>
            </tr>
            <tr>
              <td>Nepali</td>
              <td>
                <code translate="no" dir="ltr">
                  ne
                </code>
              </td>
            </tr>
            <tr>
              <td>Norwegian</td>
              <td>
                <code translate="no" dir="ltr">
                  no
                </code>
              </td>
            </tr>
            <tr>
              <td>Nyanja (Chichewa)</td>
              <td>
                <code translate="no" dir="ltr">
                  ny
                </code>
              </td>
            </tr>
            <tr>
              <td>Odia (Oriya)</td>
              <td>
                <code translate="no" dir="ltr">
                  or
                </code>
              </td>
            </tr>
            <tr>
              <td>Pashto</td>
              <td>
                <code translate="no" dir="ltr">
                  ps
                </code>
              </td>
            </tr>
            <tr>
              <td>Persian</td>
              <td>
                <code translate="no" dir="ltr">
                  fa
                </code>
              </td>
            </tr>
            <tr>
              <td>Polish</td>
              <td>
                <code translate="no" dir="ltr">
                  pl
                </code>
              </td>
            </tr>
            <tr>
              <td>Portuguese (Portugal, Brazil)</td>
              <td>
                <code translate="no" dir="ltr">
                  pt
                </code>
              </td>
            </tr>
            <tr>
              <td>Punjabi</td>
              <td>
                <code translate="no" dir="ltr">
                  pa
                </code>
              </td>
            </tr>
            <tr>
              <td>Romanian</td>
              <td>
                <code translate="no" dir="ltr">
                  ro
                </code>
              </td>
            </tr>
            <tr>
              <td>Russian</td>
              <td>
                <code translate="no" dir="ltr">
                  ru
                </code>
              </td>
            </tr>
            <tr>
              <td>Samoan</td>
              <td>
                <code translate="no" dir="ltr">
                  sm
                </code>
              </td>
            </tr>
            <tr>
              <td>Scots Gaelic</td>
              <td>
                <code translate="no" dir="ltr">
                  gd
                </code>
              </td>
            </tr>
            <tr>
              <td>Serbian</td>
              <td>
                <code translate="no" dir="ltr">
                  sr
                </code>
              </td>
            </tr>
            <tr>
              <td>Sesotho</td>
              <td>
                <code translate="no" dir="ltr">
                  st
                </code>
              </td>
            </tr>
            <tr>
              <td>Shona</td>
              <td>
                <code translate="no" dir="ltr">
                  sn
                </code>
              </td>
            </tr>
            <tr>
              <td>Sindhi</td>
              <td>
                <code translate="no" dir="ltr">
                  sd
                </code>
              </td>
            </tr>
            <tr>
              <td>Sinhala (Sinhalese)</td>
              <td>
                <code translate="no" dir="ltr">
                  si
                </code>
              </td>
            </tr>
            <tr>
              <td>Slovak</td>
              <td>
                <code translate="no" dir="ltr">
                  sk
                </code>
              </td>
            </tr>
            <tr>
              <td>Slovenian</td>
              <td>
                <code translate="no" dir="ltr">
                  sl
                </code>
              </td>
            </tr>
            <tr>
              <td>Somali</td>
              <td>
                <code translate="no" dir="ltr">
                  so
                </code>
              </td>
            </tr>
            <tr>
              <td>Spanish</td>
              <td>
                <code translate="no" dir="ltr">
                  es
                </code>
              </td>
            </tr>
            <tr>
              <td>Sundanese</td>
              <td>
                <code translate="no" dir="ltr">
                  su
                </code>
              </td>
            </tr>
            <tr>
              <td>Swahili</td>
              <td>
                <code translate="no" dir="ltr">
                  sw
                </code>
              </td>
            </tr>
            <tr>
              <td>Swedish</td>
              <td>
                <code translate="no" dir="ltr">
                  sv
                </code>
              </td>
            </tr>
            <tr>
              <td>Tagalog (Filipino)</td>
              <td>
                <code translate="no" dir="ltr">
                  tl
                </code>
              </td>
            </tr>
            <tr>
              <td>Tajik</td>
              <td>
                <code translate="no" dir="ltr">
                  tg
                </code>
              </td>
            </tr>
            <tr>
              <td>Tamil</td>
              <td>
                <code translate="no" dir="ltr">
                  ta
                </code>
              </td>
            </tr>
            <tr>
              <td>Tatar</td>
              <td>
                <code translate="no" dir="ltr">
                  tt
                </code>
              </td>
            </tr>
            <tr>
              <td>Telugu</td>
              <td>
                <code translate="no" dir="ltr">
                  te
                </code>
              </td>
            </tr>
            <tr>
              <td>Thai</td>
              <td>
                <code translate="no" dir="ltr">
                  th
                </code>
              </td>
            </tr>
            <tr>
              <td>Turkish</td>
              <td>
                <code translate="no" dir="ltr">
                  tr
                </code>
              </td>
            </tr>
            <tr>
              <td>Turkmen</td>
              <td>
                <code translate="no" dir="ltr">
                  tk
                </code>
              </td>
            </tr>
            <tr>
              <td>Ukrainian</td>
              <td>
                <code translate="no" dir="ltr">
                  uk
                </code>
              </td>
            </tr>
            <tr>
              <td>Urdu</td>
              <td>
                <code translate="no" dir="ltr">
                  ur
                </code>
              </td>
            </tr>
            <tr>
              <td>Uyghur</td>
              <td>
                <code translate="no" dir="ltr">
                  ug
                </code>
              </td>
            </tr>
            <tr>
              <td>Uzbek</td>
              <td>
                <code translate="no" dir="ltr">
                  uz
                </code>
              </td>
            </tr>
            <tr>
              <td>Vietnamese</td>
              <td>
                <code translate="no" dir="ltr">
                  vi
                </code>
              </td>
            </tr>
            <tr>
              <td>Welsh</td>
              <td>
                <code translate="no" dir="ltr">
                  cy
                </code>
              </td>
            </tr>
            <tr>
              <td>Xhosa</td>
              <td>
                <code translate="no" dir="ltr">
                  xh
                </code>
              </td>
            </tr>
            <tr>
              <td>Yiddish</td>
              <td>
                <code translate="no" dir="ltr">
                  yi
                </code>
              </td>
            </tr>
            <tr>
              <td>Yoruba</td>
              <td>
                <code translate="no" dir="ltr">
                  yo
                </code>
              </td>
            </tr>
            <tr>
              <td>Zulu</td>
              <td>
                <code translate="no" dir="ltr">
                  zu
                </code>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
      <Footer className="about-footer" />
      <GTranslate />
    </div>
  );
};
export default LangCode;
